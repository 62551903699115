import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import { appDatabasePrimaryFunctions, base } from '../../base';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import {
    cleanCSVText,
    findFirstAndLastName,
    convertToDateHourMinuteSecond,
    cleanFirebaseText,
    convertTimeStampToHumanReadable
} from '../utils/HelpfulFunction';
import DatePicker from '../utils/DatePicker';
import swal from "sweetalert2";

class AdminHome extends Component {
    constructor(props) {
        super(props);
        const todaysDateObject = new Date();
        todaysDateObject.setDate(todaysDateObject.getDate()-30);
        todaysDateObject.setHours(0,0,0,0); // Sets the time to start of the day (midnight)
        const endDateObject = new Date();
        endDateObject.setHours(23,59,59,999); // Sets the time to end of the day (11:59 pm)
        this.state = {
            games: 0,
            users: 0,
            itemsPerPage: 10,
            itemsToShow: 10,
            loading: false,
            gameUsers: 0,
            prizesWon: 0,
            gamesToShowList: [],
            searchStart: todaysDateObject,
            searchEnd: endDateObject
        };
    }

    componentDidMount(){
        this.getGames(this.state.searchStart.getTime(), this.state.searchEnd.getTime());
        this.getUsers(this.state.searchStart.getTime(), this.state.searchEnd.getTime());
        this.formQuestionsRef = base.bindToState('formQuestions', {
            context: this,
            asArray: true,
            state: 'formQuestions'
        })
        this.tenantVariablesRef = base.bindToState('tenantVariables', {
            context: this,
            state: 'tenantVariables'
        })
        // const questions = [
        //         {questionsId:'-NwajpsWZjnPHQ5UrPgP',answers: ["-NwajktKd0PPvqkWgaDS", "-NwajnD-_BxTUrU4DUEX", "-NwajpKf2FNN3dpNF4xh"]},
        //         {questionsId:"-Nwak0Q8etR5egR05kBO", answers:["-NwajyKgE4hAl6zlb2P-", "-Nwajvjz5hndiHWRQ0F7", "-Nwak-pjPFkV2t_nlRvd"]},
        //     ];
        // for(let i = 0; i<100; i++){
        //     let user = {};
        //     let userAnswer = {};
        //     let email = "leejorgensen22+"+i+"@gmail.com";
        //     let uid = btoa(email);
        //     let timeStamp = new Date().getTime();
        //     for(let questionId in questions){
        //         let questionObject = questions[questionId];
        //         let answerIndex = getRandomInt(questionObject.answers.length)
        //         userAnswer[questionObject.questionsId] = questionObject.answers[answerIndex]
        //     }
        //     userAnswer['timeStamp'] = timeStamp;
        //     user['email'] = email;
        //     user['uid'] = uid;
        //     user['lastSignIn'] = timeStamp;
        //     // console.log(userAnswer)
        //     // console.log(user)
        //     appDatabasePrimaryFunctions.ref('users').child(uid).set(user)
        //     appDatabasePrimaryFunctions.ref('userAnswers').child(uid).set(userAnswer)
        // }

        // const questions = [
        //         {questionsId:'-MzcFfCd1dPP5nBrGuoE',answers: ["-MzcFXaTaL_3aJ2NgWKe", "-MzcF_iUaCBgUW68GXeT", "-MzcFd2IPjpNwfc-fqO6"]},
        //         {questionsId:"-MzcFsJcVlkCo6yqwHbZ", answers:["-MzcFkhnKI3Q33mVLMeg", "-MzcFnhLulIu5wCeBfbM", "-MzcFqdLO2UymGUOgsQi"]},
        //         {questionsId:"-MzcG3cf2HAOF4g9Rtgt", answers: ["-MzcFx8MbQ9Vcud8PLfI", "-MzcG-2LM43WJUWXJGCu", "-MzcG1q7FrJ2rkssZXXz"]}
        // ];
        // let userAnswer = {};
        // for(let questionId in questions){
        //     let questionObject = questions[questionId];
        //     let answerIndex = getRandomInt(questionObject.answers.length);
        //     if(userAnswer["-MzcFfCd1dPP5nBrGuoE"] === "-MzcFXaTaL_3aJ2NgWKe" && userAnswer["-MzcFsJcVlkCo6yqwHbZ"] === "-MzcFqdLO2UymGUOgsQi"){
        //         const answersWithoutWinning = ["-MzcFx8MbQ9Vcud8PLfI", "-MzcG1q7FrJ2rkssZXXz"];
        //         answerIndex = getRandomInt(answersWithoutWinning.length);
        //         userAnswer[questionObject.questionsId] = answersWithoutWinning[answerIndex];
        //     } else {
        //         userAnswer[questionObject.questionsId] = questionObject.answers[answerIndex];
        //     }
        // }
        // console.log(userAnswer);
    }

    componentWillUnmount() {
        base.removeBinding(this.formQuestionsRef);
        base.removeBinding(this.tenantVariablesRef);
    }

    async getGames(start = 0, end){
        const vm = this;
        let queryString = appDatabasePrimaryFunctions.ref('gamesList').orderByChild('timeStamp').startAt(start);
        let currentGame = null;
        let currentGameObject = await appDatabasePrimaryFunctions.ref('currentGame').once('value').then(function(snapshot){
            let gameTemp = snapshot.val();
            if(gameTemp && gameTemp.timeStamp > start){
                return gameTemp;
            } else {
                return null;
            }
        })
        if(end){
            queryString = queryString.endAt(end);
            if(currentGameObject && currentGameObject.timeStamp > end){
                currentGameObject = null;
            }
        }
        currentGame = currentGameObject;
        let currentGamePrizes = 0;
        let currentGameUsers = 0;
        let gamesCount = 0;
        if(currentGame){
            currentGamePrizes = currentGamePrizes + await appDatabasePrimaryFunctions.ref('emailsSent').once('value').then(function(snapshot){
                return snapshot.numChildren()
            })
            currentGameUsers = currentGameUsers +  await appDatabasePrimaryFunctions.ref("userGameHistory").orderByChild(currentGame.id).equalTo(currentGame.id).once('value').then(function (snapshot){
                return snapshot.numChildren()
            })
            // const answersCount = await appDatabasePrimaryFunctions.ref("userAnswers").once('value').then(function(snapshot){
            //     return snapshot.numChildren()
            // })
            gamesCount++;
            currentGame.prizesWon = currentGamePrizes;
            currentGame.users = currentGameUsers;
            currentGame.currentGame = true;
        }
        queryString.once('value', function (snapshot) {
            let prizesCount = currentGamePrizes;
            let gameUsersCount = currentGameUsers;
            let gamesArray = [];
            let index = 0;
            snapshot.forEach(function (data) {
                index++;
                prizesCount += data.val().prizesWon || 0;
                gameUsersCount += data.val().users || 0;
                if(snapshot.numChildren() - vm.state.itemsPerPage < index){
                    gamesArray.unshift(data.val())
                }
            })
            if(currentGame){
                gamesArray.unshift(currentGame)
            }
            vm.setState({
                loading: false,
                games: gamesCount + snapshot.numChildren(),
                prizesWon: prizesCount,
                gameUsers: gameUsersCount,
                gamesToShowList: gamesArray
            })
        });
    }

    async getUsers(start=0, end, returnUsers=false){
        const vm = this;
        let queryString = appDatabasePrimaryFunctions.ref('users').orderByChild('signUpTime');
        if(start){
            queryString = queryString.startAt(start);
        }
        if(end){
            queryString = queryString.endAt(end);
        }
        if(returnUsers){
            return await queryString.once('value').then(function(snapshot) {
                if(snapshot.exists()){
                    return snapshot.val();
                } else {
                    return {};
                }
            })
        } else {
            let optInCounts = 0;
            queryString.once('value', function(snapshot){
                snapshot.forEach(function (data){
                    const user = data.val();
                    if(user.optIn || user.optInTwo || user.optInThree || user.optInFour){
                        optInCounts++;
                    }
                })
                vm.setState({
                    loading: false,
                    users: snapshot.numChildren(),
                    optIns: optInCounts
                })
            });
        }
    }

    async getUserData(onlyOptIns){
        this.setState({
            loading: true
        })
        let searchStart = this.state.searchStart || 0;
        let searchEnd = this.state.searchEnd;
        if(typeof searchStart === "object"){
            searchStart = searchStart.getTime()
        }
        if(searchEnd && typeof searchEnd === "object"){
            searchEnd = searchEnd.getTime()
        }
        let csv = process.env.REACT_APP_CLEAN_CLIENT_NAME || process.env.REACT_APP_FIREBASE_PROJECT_ID;
        const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0];
        csv+=",Trivia\n,\n"
        csv+="Date Data Downloaded,"+date_got+"\n";
        csv+="From,"+(searchStart?convertTimeStampToHumanReadable(searchStart):"")+"\n";
        csv+="To,"+(searchEnd?convertTimeStampToHumanReadable(searchEnd):"")+"\n,\n";
        csv += 'Email,First Name,Last Name,Phone Number,Zip Code,Address,Birthday,Opt-In,Opt-In 2,Signed Up';

        const newColumnsArray = [];

        if(this.state.formQuestions){
            for(const i in this.state.formQuestions){
                const formQuestion = this.state.formQuestions[i];
                newColumnsArray.push(formQuestion.id)
                csv+=(","+cleanCSVText(formQuestion.text||""))
            }
            if(this.state.tenantVariables.otherResponse){
                csv+=(",Other")
            }
        }

        csv+="\n";

        const vm = this;
        let timeFrameUsers = await this.getUsers(searchStart, searchEnd, true);
        for(let timeFrameUserIndex in timeFrameUsers){
            let user = timeFrameUsers[timeFrameUserIndex];
            if(onlyOptIns){
                if(!user.optIn && !user.optInTwo && !user.optInThree && !user.optInFour){
                    continue;
                }
            }
            let {firstName, secondName} = findFirstAndLastName(user.name);
            csv += (user.email || user.uid || "") + ",";
            csv += (cleanCSVText(firstName) || "") + ",";
            csv += (cleanCSVText(secondName) || "") + ",";
            csv += (user.phoneNumber || "") + ",";
            csv += (cleanCSVText(user.zipCode) || "") + ",";
            csv += (cleanCSVText(user.address) || "") + ",";
            csv += (user.birthday || "") + ",";
            csv += (user.optIn || "") + ",";
            csv += (user.optInTwo || "") + ",";
            csv += (user.signUpTime?convertToDateHourMinuteSecond(user.signUpTime):"");
            for(const indexOfColumnArray in newColumnsArray){
                const columnHeaderName = newColumnsArray[indexOfColumnArray];
                csv+= ",";
                if(user && user.form_responses && (user.form_responses[columnHeaderName] === false || user.form_responses[columnHeaderName] === true)){
                    csv += (user.form_responses[columnHeaderName] || "");
                } else {
                    csv += (user[columnHeaderName] || "");
                }
            }
            if(vm.state.tenantVariables.otherResponse){
                csv+= ",";
                csv+= (cleanCSVText(user.otherInput) || "");
            }
            csv += "\n";
        }
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        let cleanString = (searchStart?"_"+convertTimeStampToHumanReadable(searchStart):"")+(searchEnd?"_"+convertTimeStampToHumanReadable(searchEnd):"");
        cleanString = "trivia_accounts_made" + cleanString.replace(/[|&;$%@"<>()+,]/g, "").toLowerCase()+".csv"
        hiddenElement.download = cleanString;
        hiddenElement.click();
        vm.setState({
            loading:false
        })
    }

    async downloadIntenseGameData(game){
        this.setState({
            loading: true
        })
        let csv = process.env.REACT_APP_FAN_LINK + ',' + 'Trivia\n""\n';
        let date_downloaded = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0];
        const gameAnswers = await appDatabasePrimaryFunctions.ref(`userAnswersHistory/${game.id}`).once('value').then(function(snapshot){
            if(snapshot.exists()){
                return snapshot.val();
            } else {
                return []
            }
        });

        let totalPrizes = 0;
        for(const userIndex in gameAnswers){
            const userInfo = gameAnswers[userIndex];
            if(userInfo.rewardSent){
                totalPrizes++;
            }
        }
        csv+= 'Date Data Downloaded,'+date_downloaded+'\n';
        csv+= 'Game Name,'+game.gameName+'\n';
        csv+= 'Start Time,'+(game.scheduleInfo?convertTimeStampToHumanReadable(game.scheduleInfo.performAt):"")+'\n';
        csv+= 'End Time,'+(game.scheduleInfo?convertTimeStampToHumanReadable(game.scheduleInfo.endAt):"")+'\n';
        csv+= 'Prizes Won,'+totalPrizes.toString()+'\n""\n';
        csv+= 'Email,First Name,Last Name,Phone Number,Zip Code,Address,Birthday,Opt-In,Opt-In 2,Number Correct,Won?,Reward,Code,Link,Redeemed,Answer Time';

        const newColumnsArray = [];
        if(game.surveyQuestions){
            for(const i in game.surveyQuestions){
                const surveyQuestion = game.surveyQuestions[i];
                newColumnsArray.push(cleanCSVText(surveyQuestion.questionText))
                csv+=(","+cleanCSVText(surveyQuestion.questionText||""))
            }
        }

        if(this.state.formQuestions){
            for(const i in this.state.formQuestions){
                const formQuestion = this.state.formQuestions[i];
                newColumnsArray.push(formQuestion.id)
                csv+=(","+cleanCSVText(formQuestion.text||""))
            }
            if(this.state.tenantVariables.otherResponse){
                csv+=(",Other")
            }
        }

        csv+="\n";

        const usersData = await appDatabasePrimaryFunctions.ref('users').once('value').then(function(usersSnapshot){
            return usersSnapshot.val();
        })
        const vm = this;
        for(const userIndex in gameAnswers){
            const userInfo = gameAnswers[userIndex];
            const rewardSent = userInfo.rewardSent ? "YES": "NO";
            const moreUserData = usersData[userInfo.uid];
            let {firstName, secondName} = findFirstAndLastName(moreUserData.name);
            csv+= (moreUserData.email || moreUserData.uid || "")
            +','+(cleanCSVText(firstName) || "")
            +','+(cleanCSVText(secondName) || "")
            +','+(moreUserData.phoneNumber || "")
            +","+(cleanCSVText(moreUserData.zipCode) || "")
            +","+(cleanCSVText(moreUserData.address) || "")
            +','+(moreUserData.birthday||"")
            +','+(moreUserData.optIn||"")
            +','+(moreUserData.optInTwo||"")
            +','+(userInfo.correctAnswers||"")
            +','+(rewardSent||"")
            +','+(cleanCSVText(userInfo.rewardSent)||"")
            +','+(cleanCSVText(userInfo.code)||"")
            +','+(cleanCSVText(userInfo.link)||"")
            +','+(userInfo.isRedeemed?"true":"false")
            +','+convertToDateHourMinuteSecond(parseInt(userInfo.timeStamp));
            for(const indexOfColumnArray in newColumnsArray){
                const columnHeaderName = newColumnsArray[indexOfColumnArray];
                csv+= ",";
                if(moreUserData && moreUserData.form_responses && (moreUserData.form_responses[columnHeaderName] === false || moreUserData.form_responses[columnHeaderName] === true)){
                    csv += (moreUserData.form_responses[columnHeaderName] || "");
                } else {
                    csv += (userInfo[cleanFirebaseText(columnHeaderName)] || "");
                }
            }
            if(vm.state.tenantVariables.otherResponse){
                csv+= ",";
                csv+= (cleanCSVText(moreUserData.otherInput) || "");
            }
            csv += '\n';
        }
        let hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        hiddenElement.target = '_blank';
        let cleanString = game.gameName.replace(/[|&;$%@"<>()+,. ]/g, "").toLowerCase();
        hiddenElement.download = "trivia_game_data_"+cleanString+".csv";
        hiddenElement.click();
        this.setState({
            loading: false
        })
    }

    searchData(){
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if(searchStart){
            searchStart = searchStart.getTime();
        }
        if(searchEnd){
            searchEnd = searchEnd.getTime();
        }
        this.setState({
            loading: true,
            itemsPerPage: this.state.itemsPerPage
        }, ()=>{

        })
        this.getGames(searchStart,searchEnd)
        this.getUsers(searchStart,searchEnd)
    }

    onNextPage() {
        const vm = this;
        const newAmountToShow = this.state.itemsToShow + this.state.itemsPerPage;
        let searchStart = this.state.searchStart;
        let searchEnd = this.state.searchEnd;
        if(searchStart){
            searchStart = searchStart.getTime();
        }
        if(searchEnd){
            searchEnd = searchEnd.getTime();
        }
        let queryString = appDatabasePrimaryFunctions.ref('gamesList').orderByChild('timeStamp').limitToLast(newAmountToShow).startAt(searchStart);
        if (searchEnd) {
            queryString = queryString.endAt(searchEnd)
        }
        queryString.once('value', function (snapshot) {
            let gamesArray = [];
            snapshot.forEach(function (data) {
                gamesArray.unshift(data.val())
            })
            vm.setState({
                itemsToShow: newAmountToShow,
                gamesToShowList: gamesArray
            });
        })
    }

    async deleteGame(gameToDelete) {
        if (!gameToDelete || !gameToDelete.id) {
            swal('Error!', 'No game ID provided.', 'error');
            return;
        }

        const responseFromPopUp = await swal({
            title: 'WARNING!',
            text: 'This will delete ALL data associated with the game. Are you sure you want to do this?',
            type: 'warning',
            input: 'text',
            inputPlaceholder: 'Type DELETE to confirm',
            showCancelButton: true,
            confirmButtonText: 'Submit',
            preConfirm: (inputValue) => {
                if (inputValue !== 'DELETE') {
                    swal.showValidationMessage('You need to type DELETE to confirm');
                    return false;
                }
            }
        });

        if (!responseFromPopUp || !responseFromPopUp.value) return;

        this.setState({ loading: true });

        const removeNodesByGameId = async (refPath, gameIdField, isNested = false) => {
            const ref = appDatabasePrimaryFunctions.ref(refPath).orderByChild(gameIdField).equalTo(gameToDelete.id);
            const snapshot = await ref.once('value');

            if (snapshot.exists()) {
                const nodes = snapshot.val();
                const chunkSize = 50; // Define a reasonable chunk size
                let currentChunk = {};

                for (const key in nodes) {
                    if (isNested) {
                        for (const nestedKey in nodes[key]) {
                            currentChunk[`${refPath}/${key}/${nestedKey}`] = null;
                            if (Object.keys(currentChunk).length >= chunkSize) {
                                await appDatabasePrimaryFunctions.ref().update(currentChunk);
                                currentChunk = {};
                            }
                        }
                    } else {
                        if(refPath === 'userGameHistory'){
                            currentChunk[`${refPath}/${key}/${gameIdField}`] = null;
                        } else {
                            currentChunk[`${refPath}/${key}`] = null;
                        }
                        if (Object.keys(currentChunk).length >= chunkSize) {
                            await appDatabasePrimaryFunctions.ref().update(currentChunk);
                            currentChunk = {};
                        }
                    }
                }

                if (Object.keys(currentChunk).length > 0) {
                    await appDatabasePrimaryFunctions.ref().update(currentChunk);
                }
            }
        };

        try {
            // Step 1: Attempt to remove userAnswersHistory
            try {
                const userAnswersHistoryRef = appDatabasePrimaryFunctions.ref(`userAnswersHistory/${gameToDelete.id}`);
                await userAnswersHistoryRef.remove();
            } catch (error) {
                // If removal fails, chunk the deletions
                await removeNodesByGameId(`userAnswersHistory/${gameToDelete.id}`, 'gameId', true);
            }

            // Step 2: Find and remove the game
            const gameRef = appDatabasePrimaryFunctions.ref('gamesList').orderByChild('id').equalTo(gameToDelete.id);
            const snapshot = await gameRef.once('value');

            if (snapshot.exists()) {
                const gameKey = Object.keys(snapshot.val())[0];
                await appDatabasePrimaryFunctions.ref(`gamesList/${gameKey}`).remove();

                // Step 3: Remove gameId from userGameHistory
                await removeNodesByGameId('userGameHistory', gameToDelete.id);

                // Step 4: Remove entries in redeemedPrize with the same gameId
                await removeNodesByGameId('redeemedPrize', 'gameId');

                this.setState({
                    gamesToShowList: this.state.gamesToShowList.filter(game => game.id !== gameToDelete.id)
                });

                swal('Deleted!', 'The game has been deleted.', 'success');
            } else {
                swal('Error!', 'Game not found.', 'error');
            }
        } catch (error) {
            swal('Error!', 'An error occurred while deleting the game.', 'error');
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const totalUsers = this.state.users || 0;
        const optIns = this.state.optIns || 0;
        const gameUsers = this.state.gameUsers || 0;
        const prizesWon = this.state.prizesWon || 0;
        const fan_side_link = process.env.REACT_APP_FAN_LINK || "";
        const scoreboard_horizontal_link = process.env.REACT_APP_SCOREBOARD_HORIZONTAL_LINK || "";
        const scoreboard_vertical_link = process.env.REACT_APP_SCOREBOARD_VERTICAL_LINK || "";
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";

        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <div className="row" style={{width:'100%'}}>
                    <div className="col-md-11">
                        <p className="admin-header-text" style={{marginTop:'10px'}}>
                            {!isMlbApp &&
                                <>
                                    Game link: <span style={{fontSize:'20px'}}>{fan_side_link}</span>
                                </>
                            }
                            <br/>
                            Scoreboard Top Image Link: <span style={{fontSize:'20px'}}>{scoreboard_vertical_link}</span><br/>
                            Scoreboard Left Image link: <span style={{fontSize:'20px'}}>{scoreboard_horizontal_link}</span>
                        </p>
                    </div>
                    <div className="col-md-1">
                        <p style={{color: "black"}}>
                            v{process.env.REACT_APP_VERSION}
                        </p>
                    </div>
                </div>
                <form className="form-horizontal">
                    <div className="row" style={{marginLeft: 20}}>
                        <div className="form-group" style={{marginRight: 10}}>
                            <DatePicker
                                isClearable
                                selected={this.state.searchStart}
                                onChange={date => {
                                    if(date) date.setHours(0,0,0,0);
                                    this.setState({searchStart: date});
                                }}
                            />
                        </div>

                        <div className="form-group" style={{marginRight: 10}}>
                            <DatePicker
                                isClearable
                                selected={this.state.searchEnd}
                                onChange={date => {
                                    if(date) date.setHours(23,59,59,999);
                                    this.setState({searchEnd: date});
                                }}
                            />
                        </div>
                        <div>
                            <button className="btn btn-primary btn-admin" type="button" onClick={()=>this.searchData()}>Search</button>
                        </div>
                    </div>
                </form>
                <div className="admin-grid-container four-columns">
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{totalUsers}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Accounts Created</span>
                                    </div>
                                    {/*<div className="col-md-4" style={{fontSize:30, alignSelf:"center"}}>*/}
                                    {/*    <i className="fa fa-download" aria-hidden="true" style={{color:"white", cursor: "pointer"}} onClick={()=> this.getUserData()}/>*/}
                                    {/*</div>*/}
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{optIns}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Opt Ins</span>
                                    </div>
                                    <div className="col-md-4" style={{fontSize: 40, alignSelf: "center"}}>
                                        {optIns > 0 &&
                                            <i className="fa fa-download" aria-hidden="true" style={{color: "white", cursor: "pointer"}} onClick={() => this.getUserData(true)}/>
                                        }
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{gameUsers}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Game Users</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                    <div className="card card-styles text-xs-center" style={{backgroundColor:"black"}}>
                        <div className="card-body">
                            <blockquote className="card-bodyquote" style={{margin:0}}>
                                <div className="row">
                                    <div className="col-md-8">
                                        <p style={{color:"white", fontSize:50, margin:0}}>{prizesWon}</p>
                                        <span style={{color:"#f8f8ff", fontSize:20}}>Prizes Won</span>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>
                </div>
                <div style={{margin:20}}>
                    {this.state.gamesToShowList && this.state.gamesToShowList.length > 0 &&
                        <>
                            {
                                this.state.gamesToShowList.map(function(item,index){
                                    return (
                                        <div key={index} className="card card-styles text-xs-center" style={{marginBottom:10}}>
                                            <div className="card-body" style={{boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 8px 0px"}}>
                                                <div className="row" style={{alignItems: 'center'}}>
                                                    <div className="col-md-2">
                                                        <span style={{color:'black', fontSize: 14}}>{convertTimeStampToHumanReadable(item.timeStamp)}</span>
                                                    </div>
                                                    <div className="col-md-3" style={{fontSize: 30}}>
                                                        {item.gameName}
                                                    </div>
                                                    <div className="col-md-3" style={{textAlign:'right'}}>
                                                        <span style={{fontSize:25}}>{item.users || 0}</span>
                                                        <span style={{color:'black', fontSize: 15, marginRight: 15}}>Played</span>
                                                        <span style={{fontSize:25}}>{item.prizesWon || 0}</span>
                                                        <span style={{color:'black', fontSize: 15}}>Prizes Won</span>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="row" style={{fontSize: 20, justifyContent: 'center'}}>
                                                            {item.prizesWon !== null && !item.currentGame &&
                                                                <button onClick={() => this.downloadIntenseGameData(item)} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                                    <span className="fa fa-arrow-circle-down"/> Download Game Data
                                                                </button>
                                                            }
                                                            {item.currentGame &&
                                                                <button onClick={() => window.location.href='/setupgame'} className="export-button-styles btn btn-primary btn-lg download-button mobile-hide">
                                                                    Go To Current Game
                                                                </button>
                                                            }
                                                            {!item.currentGame &&
                                                                <button onClick={() => this.deleteGame(item)} className="btn btn-danger btn-sm">
                                                                    Delete
                                                                </button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }, this)
                            }
                            {this.state.games > this.state.gamesToShowList.length &&
                                <button className="btn btn-primary" onClick={()=>this.onNextPage()}>
                                    More
                                </button>
                            }
                        </>
                    }
                </div>
            </div>
         </div>
        );
    }
}

export default AdminHome
