import RichTextEditor from "html-verify-react-rte";

export const cleanCSVText = (textToClean) => {
    if (textToClean && typeof textToClean === "string"){
        return textToClean.replace(/,/g, '')
    } else {
        return textToClean;
    }
}

export const cleanFirebaseText = (textToClean) => {
    if (textToClean && typeof textToClean === "string"){
        textToClean = textToClean.replace(".", "");
        textToClean = textToClean.replace("$", "");
        textToClean = textToClean.replace("#", "");
        textToClean = textToClean.replace("[", "");
        textToClean = textToClean.replace("]", "");
        textToClean = textToClean.replace("/", "");
        return textToClean
    }
    return textToClean;
}

export const valueDoesExist = (value) => {
    return !(value === false || value === "" || value === null || value === undefined);
}

export const validUrl = (str) => {
    return validUrlFunction(str)
}

function validUrlFunction(str) {
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export const findFirstAndLastName = (nameString) => {
    let firstName = "";
    let secondName = "";
    if(nameString && nameString.trim()) {
        nameString = nameString.trim();
        if (nameString.indexOf(" ") !== -1) {
            let splitName = nameString.split(" ");
            if (splitName.length >= 2) {
                secondName = splitName.pop()
            }
            firstName = splitName.join(" ");
        }
    }
    return {firstName: firstName, secondName: secondName}
}

export const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const isObjectEmpty = (obj) =>  {
    for(let prop in obj) {
        if(obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return JSON.stringify(obj) === JSON.stringify({});
}

export const validateEmail = (email) => {
    let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(email);
}

function toStandardTime(value) {
    if (value !== null && value !== undefined){ //If value is passed in
        if(value.indexOf('AM') > -1 || value.indexOf('PM') > -1){ //If time is already in standard time then don't format.
            return value;
        }
        else {
            if(value.length === 8){ //If value is the expected length for military time then process to standard time.
                let hour = parseInt(value.substring ( 0,2 )); //Extract hour
                const minutes = value.substring ( 3,5 ); //Extract minutes
                let identifier = 'AM'; //Initialize AM PM identifier

                if(hour === 12){ //If hour is 12 then should set AM PM identifier to PM
                    identifier = 'PM';
                }
                if(hour === 0){ //If hour is 0 then set to 12 for standard time 12 AM
                    hour=12;
                }
                if(hour > 12){ //If hour is greater than 12 then convert to standard 12 hour format and set the AM PM identifier to PM
                    hour = hour - 12;
                    identifier='PM';
                }
                return hour.toString() + ':' + minutes + ' ' + identifier; //Return the constructed standard time
            }
            else { //If value is not the expected length than just return the value as is
                return value;
            }
        }
    }
}

function toDate(date) {
    if (date === void 0) {
        return new Date(0);
    }
    if (isDate(date)) {
        return date;
    } else {
        return new Date(parseFloat(date.toString()));
    }
}

function isDate(date) {
    return (date instanceof Date);
}

function format(date, format) {
    var d = toDate(date);
    return format
        .replace(/Y/gm, d.getFullYear().toString())
        .replace(/m/gm, ('0' + (d.getMonth() + 1)).substr(-2))
        .replace(/d/gm, ('0' + (d.getDate())).substr(-2))
        .replace(/H/gm, ('0' + (d.getHours() + 0)).substr(-2))
        .replace(/i/gm, ('0' + (d.getMinutes() + 0)).substr(-2))
        .replace(/s/gm, ('0' + (d.getSeconds() + 0)).substr(-2))
        .replace(/v/gm, ('0000' + (d.getMilliseconds() % 1000)).substr(-3));
}

export const getSnapshotFromEndpoint = (endpoint, appDatabasePrimaryFunctions, numberOfResults=null, searchData=null, orderBy=null, equalTo=null) => {
    let baseRef = appDatabasePrimaryFunctions.ref(endpoint);
    if(orderBy){
        baseRef = baseRef.orderByChild(orderBy)
        if(equalTo){
            baseRef = baseRef.equalTo(equalTo)
        }
    }
    if(numberOfResults){
        baseRef = baseRef.limitToFirst(numberOfResults);
    }
    return baseRef.once('value').then(function(snapshot){
        return snapshot;
    })
}

export const convertTimeStampToHumanReadable = (timeStamp) => {
    if(!timeStamp){
        return null
    }
    const dateToConvert = new Date(returnTimeStampInMilliseconds(timeStamp));
    const formattedTimeSplit = dateToConvert.toString().split(" ");
    formattedTimeSplit[4] = toStandardTime(formattedTimeSplit[4]);
    //remove bullshit returned by date
    formattedTimeSplit.splice(5,4);
    //get short timezone
    formattedTimeSplit.push(dateToConvert.toLocaleString('en', {timeZoneName:'short'}).split(' ').pop());
    return formattedTimeSplit.join(" ");
};

export const convertToDateHourMinuteSecond = (timeStamp) => {
    if(!timeStamp){
        return null
    }
    const timeStampToConvert = returnTimeStampInMilliseconds(timeStamp);
    return format(timeStampToConvert, "Y-m-d H:i:s");
};

export const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
}

export const returnTimeStampInMilliseconds = (timeStamp) => {
    //check if timeStamp is in seconds or milliseconds
    //Its 2020 so for now we can assume milliseconds if timestamp has 13 or more digits
    //This should work until the year 33658 so thats good
    //is milliseconds and javascript prefers milliseconds
    if(!timeStamp){
        return timeStamp;
    } else {
        timeStamp = parseInt(timeStamp);
    }
    let stringTimeStampLength = 0;
    if(typeof timeStamp !== "string"){
        stringTimeStampLength = timeStamp.toString().length;
    } else {
        stringTimeStampLength = timeStamp.length;
        timeStamp = parseInt(timeStamp);
    }
    if(stringTimeStampLength < 13){
        //is seconds convert to milliseconds
        return timeStamp*1000
    } else {
        return timeStamp
    }
}

export const readyRichTextForSubmit = (text) => {
    text = text || RichTextEditor.createEmptyValue();
    text = text.toString('html');
    if(text === "<p><br></p>"){
        text = "";
    }
    return text
}

export const detectHowManyCodesEntered = (codesArray, areLinks) => {
    if(!codesArray){
        return [];
    }
    codesArray = codesArray.trim();
    const splitOnLineBreaks = codesArray.split("\n");
    let splitOnWhiteSpace = codesArray.split(/\s+/);
    let splitOnCommas = codesArray.split(",");
    let splitArray = splitOnLineBreaks;
    if(splitOnWhiteSpace.length === splitOnCommas.length){
        splitOnWhiteSpace = codesArray.replace(/,/g, '').split(/\s+/);
        splitOnCommas = codesArray.replace(/\s/g,'').split(",");
    }
    if(splitArray.length < splitOnWhiteSpace.length){
        splitArray = splitOnWhiteSpace
    }
    if(splitArray.length < splitOnCommas.length){
        splitArray = splitOnCommas
    }
    if(areLinks){
        for(const linkIndex in splitArray){
            const link = splitArray[linkIndex];
            if(!validUrl(link)){
                return {message:"Invalid Url", inValidUrl: link};
            }
        }
    }
    return splitArray
}

export const returnCorrectRichTextObject = (text) => {
    if(!text){
        return RichTextEditor.createEmptyValue();
    }
    if(typeof text === 'string') {
        text = RichTextEditor.createValueFromString(text, 'html')
    }
    return text;
}
